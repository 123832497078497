

function PageNotFound () {

    return (
        <div>
            The Page you were looking for was not found!
            Please ensure you have typed in the correct URL
        </div>
    )
}

export default PageNotFound