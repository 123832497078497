import React, {useState} from 'react'

import './HeaderButtonStyles.css'

function CustomHeaderButton({text, onClick}) {

    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseExit = () => {
        setIsHovered(false)
    }

    const buttonStyle = {
        backgroundColor: isHovered ? 'black': '#9b14ff',
        backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.7)': 'rgba(155, 20, 255,1)',
        transition: 'background-color 0.3s ease'
    }

    return (
        <button className='hover-header-button'
        style={buttonStyle}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseExit}
        >{text}</button>
    )
}

export default CustomHeaderButton