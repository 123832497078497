
import './PrivacyPolicyStyles.css'


function PrivacyPolicy () {
    return(
        <div className="privacy-policy-body">
            <h1 className="title">Privacy Policy</h1>
            <p class="paragraph-text">
            <br />
            <strong
                >Hour Eats Privacy Policy describes how Hour Eats collects, uses and
                shares your personal data.</strong
            ><br />
            Updated October 24, 2023<br />
            <br />
            At Hour Eats, we believe your right to privacy. This means that we strive
            to collect only the personal data that we need to provide the best
            services Hour Eats has to offer. When you use Hour Eats services, we
            may collect the location of the user's device. When an account is
            created, then the email and restaurants in the
            lists are collected as well.
            </p>

            <p class="paragraph-text">
            The location collected is in no way tied to the user's identity, but is used to provide certain features to the user. This
            location is never permanently stored at HourEat. The email and restaurants are
            permanently saved, but are not shared nor viewed by any services besides
            Hour Eats.
            <br />
            <br/>
            You are not required to provide the personal data that we have requested.
            However, if you choose not to do so, we will not be able to provide you
            with our products or services or respond to requests you may have.
            <br />
            <br />
            Hour EatsEats may share personal data with service providers who act on our behalf
            or government agencies as required by law.
            </p>
        </div>
    )
}

export default PrivacyPolicy