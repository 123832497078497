import './HeaderStyles.css'
import logo from '../../Images/HourEatsIcon.png'
import React, {useEffect, useState} from 'react';

import CustomHeaderButton from "../../CustomProps/CustomHeaderButton";
import BlackCustomHeaderButton from "../../CustomProps/BlackCustomHeaderButton";

import Dropdown from 'react-bootstrap/Dropdown'

import dropdownImg from '../../Images/DropDownImg.png'


import { useNavigate, Outlet } from "react-router-dom";
import Button from 'react-bootstrap/esm/Button';

import { useAuth0 } from "@auth0/auth0-react";

function Header() {

    const { isAuthenticated } = useAuth0();

    let navigate = useNavigate();
    
    const homeRoute = () => { 
      window.scrollTo({top:0})
      let path = `/`; 
      navigate(path);
    }

    const supportedRestaurantsRoute = () => { 
      window.scrollTo({top:0})
      let path = `/supported-restaurants`; 
      navigate(path);
    }

    const privacyPolicyRoute = () => { 
      window.scrollTo({top:0})
        let path = `/privacy-policy`; 
        navigate(path);
      }

    const contactUsRoute = () => {
      window.scrollTo({top:0})
      let path = '/contact-us'
      navigate(path)
    }

    const RegisterRestaurantRoute = () => {
      window.scrollTo({top:0})
      let path = '/register'
      navigate(path)
    }

    const ManageAccountRoute = () => {
      window.scrollTo({top:0})
      let path = '/account'
      navigate(path)
    }

    const downloadRoute = () => {
      window.scrollTo({top:0})
      let path = '/download'
      navigate(path)
    }

    const [isMobile,setIsMobile] = useState(false)

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 1380)
      }

      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    },[])



    const handleInstallClick =() => {
      const user = navigator.userAgent || navigator.vendor || window.opera

      const isApple = /iPad|iPhone|iPod/.test(user) && !window.MSStream;

      if (isApple) {
        window.location.href = 'https://apps.apple.com/us/app/houreats-fast-food-dining/id1568882345'
      }
      else {
        alert('HourEats is not supported on your device.')
      }
    }


    return (
        <div >
        {isMobile ? (
          <div className='header'>
            
            <div style={{display: 'flex', flex: '1'}}>
            
            <Dropdown>
                <Dropdown.Toggle className='custom-toggle' variant='custom'>
                <img src={dropdownImg} className = 'header-button-image' />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={homeRoute}>Home</Dropdown.Item>
                  <Dropdown.Item onClick={supportedRestaurantsRoute}>Supported Restaurants</Dropdown.Item>
                  <Dropdown.Item onClick={privacyPolicyRoute}>Privacy Policy</Dropdown.Item>
                  <Dropdown.Item onClick={contactUsRoute}>Contact Us</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            
            <div style={{display: 'flex', flex: '1', justifyContent: 'center'}}>
              <img src={logo} className = 'logo' />
            </div>

            <div style={{display: 'flex', flex: '1', justifyContent: 'flex-end'}}>
              <Button variant="custom" className='download-button' onClick={handleInstallClick}>Download</Button>
            </div>
           
          </div>        
        ) : 
        (
          <div className='header'>

            <div className='header-div-1'>
                <img src={logo} className = 'logo' />
          
                <div >
                    <CustomHeaderButton text='Home' onClick={homeRoute}/>
                    <CustomHeaderButton text='Supported Restaurants' onClick={supportedRestaurantsRoute}/>
                    <CustomHeaderButton text='Privacy Policy' onClick={privacyPolicyRoute}/>
                    <CustomHeaderButton text='Contact Us' onClick={contactUsRoute}/>
                  
                </div>
            </div>
            <div className='header-div-2'>
              {/* {isAuthenticated ? <BlackCustomHeaderButton text='Manage Restaurant' onClick={ManageAccountRoute}/> :<BlackCustomHeaderButton text='Register Restaurant' onClick={RegisterRestaurantRoute}/> } */}
                <BlackCustomHeaderButton text='Download' onClick={downloadRoute}/>
            </div>
            
          </div>
          )
        }
        <Outlet />
      </div>
    )
}

export default Header