
import './SupportedResuarantsStyles.css'

import ApplebeesLogo from './ApplebeesLogo.png'
import ArbysLogo from './ArbysLogo.png'
import BuffaloWildWingsLogo from './BuffaloWildWingsLogo.png'
import ChickFilALogo from './ChickFilALogo.png'
import ChipotleLogo from './ChipotleLogo.png'
import ChilisLogo from './ChilisLogo.png'
import CrackerBarrelLogo from './CrackerBarrelLogo.png'
import DairyQueenLogo from './DairyQueenLogo.png'
import DunkinDonutsLogo from './DunkinDonutsLogo.png'
import IHOPLogo from './IHOPLogo.png'
import JackInTheBoxLogo from './JackInTheBoxLogo.png'
import JimmyJohnsLogo from './JimmyJohnsLogo.png'
import KFCLogo from './KFCLogo.png'
import McdonaldsLogo from './McDonaldsLogo.png'
import OutbackSteakhouseLogo from './OutbackSteakhouseLogo.png'
import PandaExpressLogo from './PandaExpressLogo.png'
import PapaJohnsLogo from './PapaJohnsLogo.png'
import PizzaHutLogo from './PizzaHutLogo.png'
import SonicLogo from './SonicLogo.png'
import StarbucksLogo from './StarbucksLogo.png'
import SubwayLogo from './SubwayLogo.png'
import TacoBellLogo from './TacoBellLogo.png'
import WendysLogo from './WendysLogo.png'

import WhataburgerLogo from './WhataburgerLogo.png'


function SupportedRestaurants () {
    return(
        <div className="body">
            <h1 className="text">Supported Restaurants</h1>
            <br/>
            <ul className="list"> 

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={ApplebeesLogo}/>
                        <h2 className="text">Applebee's</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={ArbysLogo}/>
                        <h2 className="text">Arby's</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={BuffaloWildWingsLogo}/>
                        <h2 className="text">Buffalo Wild Wings</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={ChickFilALogo}/>
                        <h2 className="text">Chick-fil-A</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={ChipotleLogo}/>
                        <h2 className="text">Chipotle</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={ChilisLogo}/>
                        <h2 className="text">Chili's</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={CrackerBarrelLogo}/>
                        <h2 className="text">Cracker Barrel</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={DairyQueenLogo}/>
                        <h2 className="text">Dairy Queen</h2>
                    </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={DunkinDonutsLogo}/>
                        <h2 className="text">Dunkin Donuts</h2>
                    </div>
                </li>
                
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={IHOPLogo}/>
                        <h2 className="text">IHOP</h2>
                    </div>
                </li>
                
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={JackInTheBoxLogo}/>
                        <h2 className="text">Jack In The Box</h2>
                    </div>
                </li>
                
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={JimmyJohnsLogo}/>
                        <h2 className="text">Jimmy John's</h2>
                    </div>
                </li>
                
                <br/>

                
                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={KFCLogo}/>
                        <h2 className="text">KFC</h2>
                    </div>
                </li>
                
                <br/>

                <li>

                    <div className="list-item">
                        <img className="restaurant-logo" src={McdonaldsLogo}/>
                        <h2 className="text">McDonalds</h2>
                    </div>
                </li>

                <br/>

                <li>

                <div className="list-item">
                    <img className="restaurant-logo" src={OutbackSteakhouseLogo}/>
                    <h2 className="text">Outback Steakhouse</h2>
                </div>
                </li>

                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={PandaExpressLogo}/>
                        <h2 className="text">Panda Express</h2>
                    </div>
                </li>
                
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={PapaJohnsLogo}/>
                        <h2 className="text">Papa John's</h2>
                    </div>
                </li>
                
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={PizzaHutLogo}/>
                        <h2 className="text">Pizza Hut</h2>
                    </div>
                </li>
                
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={SonicLogo}/>
                        <h2 className="text">Sonic</h2>
                    </div>
                </li>
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={StarbucksLogo}/>
                        <h2 className="text">Starbucks</h2>
                    </div>
                </li>
            
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={SubwayLogo}/> 
                        <h2 className="text">Subway</h2>
                    </div>
                </li>
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={TacoBellLogo}/>
                        <h2 className="text">Taco Bell</h2>
                    </div>
                </li>
                
                <br/>

                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={WendysLogo}/>
                        <h2 className="text">Wendy's</h2>
                    </div>
                </li>
                
                <br/>


                <li>
                    <div className="list-item">
                        <img className="restaurant-logo" src={WhataburgerLogo}/>
                        <h2 className="text">Whataburger</h2>
                    </div>
                </li>
                
                <br/>

            </ul>
        </div>
    )
}

export default SupportedRestaurants