import React, { useEffect, useRef } from 'react';

const AutoPlayVideo = ({ thumbnail, videoSource }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (!isMobile) {
      // Add the autoplay attribute only if the user is not on a mobile device
      videoElement.setAttribute('autoplay', true);
    }

    // Rest of your Intersection Observer and other logic...

    return () => {
      // Clean up if needed
    };
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        className="resizable-video"
        controls
        poster = {thumbnail}
        muted
        loop
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default AutoPlayVideo;
