
import './ContactUsStyles.css'
import InstagramImg from './InstagramLinkImage.png'
import YoutubeImg from './YoutubeLinkImage.png'
import TwitterImg from './TwitterLinkImage.png'
import ImageLinkButton from './ImageLinkButton.js'
import TikTokLinkImage from './TikTokLinkImage.png'

function ContactUs() {

    const businessEmail = `mailto:houreatsapp@gmail.com?subject=${encodeURIComponent('Business Inquiry')}`
    const suggestionsEmail = `mailto:houreatsapp@gmail.com?subject=${encodeURIComponent('HourEats Suggestion')}`
    return(


    <div className='contact-body'>
        <h1 className='text'>Contact Information</h1>

        <br/>


        <h3 className='text'>Social Media</h3>
        <h4 className='text'> Visit our social media accounts to see whats new with HourEats!</h4>

            <ImageLinkButton className='link-image' linkURL={'https://www.instagram.com/houreats'} imageURL={InstagramImg} Text={'Instagram'}/>
            <ImageLinkButton className='link-image' linkURL={'https://www.tiktok.com/@houreatsapp'} imageURL={TikTokLinkImage} Text={'TikTok'}/>
            <ImageLinkButton className='link-image' linkURL={'https://www.youtube.com/channel/UC_0C6B72J1aPrv1t94MADAA'} imageURL={YoutubeImg} Text={'Youtube'}/>
            <ImageLinkButton className='link-image' linkURL={'https://twitter.com/HourEats'} imageURL={TwitterImg} Text={'Twitter'}/>
  
        <br/>


        <h3 className='text'>Business Inquiries</h3>
        <h4 className='text'>For business related inquires, please reach out by sending any emails to <a href={businessEmail}>houreatsapp@gmail.com</a></h4>

        <br/>
        
        <h3 className='text'>Suggestions</h3>
        <h4 className='text'>For any suggestions relating to HourEats, please send the suggestions via email to <a href={suggestionsEmail}>houreatsapp@gmail.com</a></h4>
                
    </div>
    )
}

export default ContactUs