import './App.css';
import Home from './UserSite/HomeView/Home.js'
import HeaderView from './UserSite/HeaderView/Header.js'
import SupportedRestaurants from './UserSite/SupportedRestaurantsView/SupportedRestaurants'
import PrivacyPolicy from './UserSite/PrivacyPolicyView/PrivacyPolicy';
import ContactUs from './UserSite/ContactUsView/ContactUs'
import RegisterRestaurant from './UserSite/RegisterRestaurantView/RegisterRestaurant';
import Download from './UserSite/DownloadView/Download';
import CreateAccount from './AccountCreation/CreateAccount/CreateAccount';
import CreateOrganization from './AccountCreation/CreateOrganization/CreateOrganization'
import CreateRestaurant from './AccountCreation/CreateRestaurant/CreateRestaurant'
import OrganizationSubmission from './AccountCreation/AccountSubmission/OrganizationSubmission';
import RestaurantSubmission from './AccountCreation/AccountSubmission/RestaurantSubmission';

import MerchantHeaderView from './MerchantView/MerchantHeader/MerchantHeader';
import Account from './MerchantView/Account/Account';
import Submissions from './MerchantView/Account/Submissions/Submissions';

import AuthenticationGuard from './CustomProps/AuthenticationGuard';
import PageNotFound from './CustomProps/PageNotFound';
import PageLoader from './CustomProps/PageLoader';

import 'bootstrap/dist/css/bootstrap.min.css'

import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";

function App() {

  // const { isLoading } = useAuth0();

  // if (isLoading) {
  //   return (
  //     <div className="page-layout">
  //       <PageLoader />
  //     </div>
  //   );
  // }

  return (

      <Router>
        <Routes>

          <Route path="" element={<HeaderView />}>
            <Route
              path="/supported-restaurants"
              element={<SupportedRestaurants />}
            />

            <Route 
              path="/privacy-policy" 
              element={<PrivacyPolicy />} 
            />

            <Route 
              path="/contact-us" 
              element={<ContactUs />} 
            />

            {/* <Route 
              path="/register" 
              element={<RegisterRestaurant />} 
            /> */}

            <Route 
              path="/download" 
              element={<Download />} 
            />

            <Route path='*' element={<PageNotFound/>} />

            <Route exact path=''element={<Home />} />

          </Route>
      
          {/* <Route path="/account" element={<AuthenticationGuard component={MerchantHeaderView}/> }>

            <Route
              path="create-account"
              element={<AuthenticationGuard component= {CreateAccount} />}
            />

            <Route 
              path="create-organization" 
              element={<AuthenticationGuard component= {CreateOrganization} />}
            />

            <Route 
              path="create-restaurant" 
              element={<AuthenticationGuard component= {CreateRestaurant} />}
            />


            <Route 
              path="create-restaurant" 
              element={<AuthenticationGuard component= {CreateRestaurant} />}
            />

            <Route 
              path="restaurant-submission" 
              element={<AuthenticationGuard component= {RestaurantSubmission} />}
            />


            <Route 
              path="organization-submission" 
              element={<AuthenticationGuard component= {OrganizationSubmission} />}
            />

            <Route 
              path="submissions" 
              element={<AuthenticationGuard component= {Submissions} />}
            />

            <Route 
              path="" 
              element={<AuthenticationGuard component= {Account} />}
            />

          </Route> */}
        </Routes> 
      </Router>

  )
}

export default App;
