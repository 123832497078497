
import React from 'react'

const ImageLinkButton = ({imageURL, linkURL, text, className}) => {
    
    return(
        <a style={{paddingBottom: '1%'}} href={linkURL} target='_blank' rel='noopener noreferrer'>
            <img src={imageURL} alt={text}  className={className}/>
        </a>
    )
}


export default ImageLinkButton