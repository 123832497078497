
import './HomeStyles.css'

import React, {useEffect, useState} from 'react';
import AutoPlayVideo from './AutoPlayVideo';

import listsVideo from './ListsVideo.mp4'
import foodieVideo from './FoodieVideo.mp4'
import searchVideo from './SearchVideo.mp4'

import listsThumbnail from './ListsViewImage.jpeg'
import foodieThumbnail from './FoodieViewImage.jpeg'
import searchThumbnail from './SearchViewImage.jpeg'

function Home () {

    const [isMobile,setIsMobile] = useState(false)

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 900)
      }

      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    },[])
    

    return(
      <div className='home-body'>
            {isMobile ? (
                <div>
                    <div className='vertical-div'>
                        <h3 className='mobile-text'>
                            The only app you need for all your restaurants. HourEats allows you to view the closing/opening times of restaurants, order through DoorDash, UberEats, and Gruhub, and do much more all in one app.
                        </h3>

                        <div className='mobile-video '>
                            <AutoPlayVideo thumbnail={listsThumbnail} videoSource={listsVideo} />
                        </div>
                    </div>

                    <div className='vertical-div'>

                        <h3 className='mobile-text'>
                            Foodie is your own personal assistant that can tell you all about the restaurants in your area. They will recommend you restaurants based on your lists, tell you what restaurants are nearby, and even tell you what is currently open.
                        </h3>

                        <div className='mobile-video '>
                            <AutoPlayVideo thumbnail={foodieThumbnail}  videoSource={foodieVideo} />
                        </div>



                    </div>


                    <div className='vertical-div'>

                        <h3 className='mobile-text'>
                            Search by looking for what you want. Whether that is a specific item like tacos or a cuisine you haven't had for a while, HourEats will provide you restaurants that match what you are looking for.
                        </h3>

                        <div className='mobile-video '>
                            <AutoPlayVideo thumbnail={searchThumbnail} videoSource={searchVideo} />
                        </div>

                    </div>
                    <br/>
                </div>
            ):
            (
                <div>

                    <div className='horizontal-div'>

                        <h1 className='header-text'>
                            The only app you need for all your restaurants. HourEats allows you to view the closing/opening times of restaurants, order through DoorDash, UberEats, and Gruhub, and do much more all in one app.
                        </h1>
                         <div className='video'>
                            <AutoPlayVideo thumbnail={listsThumbnail} videoSource={listsVideo} />
                        </div>
                       
                    </div>

                    <div className='horizontal-div'>

                        <h1 className='header-text'>
                            Foodie is your own personal assistant that can tell you all about the restaurants in your area. They will recommend you restaurants based on your lists, tell you what restaurants are nearby, and even tell you what is currently open.
                        </h1>

                        <div className='video'>
                            <AutoPlayVideo thumbnail={foodieThumbnail} videoSource={foodieVideo} />
                        </div>
                
                    </div>


                    <div className='horizontal-div'>

                        <h1 className='header-text'>
                           Search by looking for what you want. Whether that is a specific item like tacos or a cuisine you haven't had for a while, HourEats will provide you restaurants that match what you are looking for.
                        </h1>

                        <div className='video'>
                            <AutoPlayVideo thumbnail={searchThumbnail} videoSource={searchVideo}  />
                        </div>

                    </div>
                    <br/>
                </div>
            )}
        </div>
    )
}

export default Home