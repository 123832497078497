
import './DownloadStyles.css';

function Download() {
    return(
        <div className="download-body">
            <h1 className="title">Scan To Download HourEats on the Apple App Store!</h1>
            <img src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/077498bcca3634fa8d6a0a53ae89ec12/f74a193025a8aea04676c8d4eafbbd53.png"/>
        </div>
    )
}

export default Download